/*
 * Copyright © 2017 - 2019 Atilika Inc. All rights reserved.
 */

import React, {memo} from "react";
import CareersPosition from "../../careers/CareersPosition";
import ContactUs from "../../common/ContactUs";
import Footer from "../../common/Footer";
import Intro from "../../common/Intro";
import Page from "../../common/Page";
import Link from "../../common/Link";
import Meta from "../../common/Meta";
import PlainHeader from "../../common/PlainHeader";
import Section from "../../common/Section";
import {H4} from "../../common/typography/Headings";
import {LI, UL} from "../../common/typography/List";

const CareersJa = () => (
    <Page lang="ja" path="/ja/careers/" title="Careers">
        <Meta name="description">
            Atilika is a natural language processing and AI company with offices in Tokyo and Oslo.
            Leading businesses worldwide rely on our technology products and deep learning
            solutions.
        </Meta>
        <PlainHeader />

        <Intro title="採用情報">
            <p>
                最先端の技術を活用し、注目度の高い顧客ソリューションの分野で自分の能力を発揮したいとお望みであれば、
                アティリカはあなたにとって最適な場所かもしれません。
            </p>
        </Intro>

        <Section title="入社するメリット" grey>
            <UL>
                <LI>
                    私たちはチームメンバーが働きやすいように、アットホームかつフレンドリーな仕事環境、柔軟な労働時間、
                    そしてゆったりとした休暇を提供しています。
                </LI>

                <LI>アティリカはキャリアの育成プランおよび外国語トレーニングを提供しています。</LI>

                <LI>会社の方向性および一人一人のタスクについては全社員の意見を尊重しています。</LI>

                <LI>
                    働きながらプログラミング、データサイエンス、自然言語処理などの分野においての最先端な技術や知識について学べます。
                </LI>

                <LI>オープンソースソフトウェアの仕事もできます。</LI>

                <LI>
                    通信・メディア、EC、出版、それぞれの業界リーダである主要企業のプロジェクトに参加できます。
                </LI>
            </UL>
        </Section>

        <Section title="募集情報">
            <p>下記のポジジョンを募集しています：</p>
        </Section>

        <Section title="インターン・ソフトウェア・エンジニア" grey>
            <CareersPosition>
                <p>
                    東京またはオスロ（ノルウェー）の開発チームで弊社のインターンシッププログラムに参加希望の
                    情熱的かつ有望なプログラマーを募集しています。
                </p>

                <p>弊社のインターンは下記のような多くのアクティビティーに参加していただきます：</p>

                <UL>
                    <LI>研究をもとにした新しい技術のプロトタイプの実装</LI>

                    <LI>弊社の製品および技術の新機能の実装</LI>

                    <LI>オープンソースへの貢献</LI>

                    <LI>弊社の顧客のコンサルティングプロジェクトへの貢献</LI>
                </UL>

                <H4>インターンシップの期間</H4>

                <p>
                    インタンーシップの期間については柔軟に調整できます。２ヶ月間から１２ヶ月間までのインタンーシップを提供しています。
                    開始日と終了日についてはご相談の上、一緒に決定します。パートタイムまたはフルタイムのインタンーシップを提供しています。
                </p>

                <H4>報酬</H4>

                <p>
                    インターンシップ中の時給は2250円です。自宅からオフィスまでの交通費も支給します。
                </p>

                <H4>望ましい知識・スキル</H4>

                <UL>
                    <LI>
                        下記のプログラミング言語においての高いプログラミングスキル：{" "}
                        Java、Python、またはJavaScript
                    </LI>
                    <LI>
                        ウェブアプリの開発経験、HTML、CSS、ReactまたはjQueryのようなツールの経験があればプラスです。
                    </LI>
                    <LI>情報検索または自然言語処理の経験があればさらにプラスです。</LI>
                    <LI>
                        Lucene、Solr、Elasticsearch、PostgreSQLなどのシステムの経験があればより良いです。
                    </LI>
                    <LI>
                        英語能力は必須ではありませんが高いコミュニケーションスキルは求められます。
                    </LI>
                    <LI>コンピュータサイエンスなどの学士または修士の学歴が望ましいです。</LI>
                </UL>

                <H4>応募方法</H4>

                <p>
                    応募するには <Link href="mailto:careers@atilika.com">careers@atilika.com</Link>{" "}
                    までインターンシップの希望オフィスを記載の上、レジュメと簡単な自己紹介を送りください。{" "}
                    ご連絡をお待ちしております！
                </p>
            </CareersPosition>
        </Section>

        <ContactUs title="Get in touch" email="careers@atilika.com">
            <p>
                優秀なソフトウェアエンジニアを募集しています。興味のある方のご連絡をお待ちしております！
            </p>
        </ContactUs>

        <Footer lang="ja" />
    </Page>
);

export default memo(CareersJa);
